import React from 'react';
import { useTheme } from './ThemeContext';
// import "./Footer.css";
import "./HoverAnimation.css";
import { VarsityLogo } from './VarsityLogo';
import { CustomCSS } from '../types/CustomCSS';
import { Link, useLocation } from 'react-router-dom';

export default function Footer() {
    const t = useTheme();

    // erm... a little hack
    const { pathname } = useLocation();
    if (pathname === '/map') {
        return (<></>);
    }

    return (

        <footer className="flex justify-center h-[300px] w-full bg-[var(--current-background)] text-[var(--current-text)] p-10 overflow-hidden"
             style={{'--current-background': t.themeState.navPrimaryColor, 
                     '--current-text': t.themeState.navInverseColor,
                     '--current-hover-background': t.themeState.navInverseColor, 
                     '--current-hover-text': t.themeState.navPrimaryColor
             } as CustomCSS}>
            <div className='flex flex-col gap-y-6 h-full max-w-96 justify-center items-center'>
                <div className='relative'>
                    <div className='absolute left-1 top-1 bg-white h-5/6 w-5/6 rounded-full'></div>
                    <Link to='https://thevarsity.ca/' target='_blank' rel='noreferrer'>
                        <VarsityLogo  className="relative size-14" />
                    </Link>
                </div>
                <a href='https://thevarsity.ca/' target='_blank' rel='noreferrer'>
                    <img src='/nameplate.png' className="w-full aspect-auto brightness-0 saturate-100" 
                         alt="The Varsity's name, capitalized"></img>
                </a>
                <div className='flex justify-between w-full font-semibold'>
                    <Link to='/credits' className='hover-animation px-2 py-1'>
                        <p className='text-2xl'>CREDITS</p>
                    </Link>
                    <Link to='https://volunteer.thevarsity.ca' target='_blank' rel='noreferrer' 
                       className='hover-animation px-2 py-1'>
                        <p className='text-2xl'>JOIN US</p>
                    </Link>
                </div>
            </div>
        </footer>
    );
}