import React, { useEffect } from 'react';
import letterImg from './EditorLetter/Masthead-advice-WEB 1.jpg'
import './EditorLetter/EditorLetter.css'
import { LetterType } from '../types/LetterType';
import { useTheme } from './ThemeContext';
import Markdown from 'react-markdown';

export default function EditorLetter({ letter }: { letter: LetterType }) {
    const t = useTheme();
    useEffect(() => {
        t.setThemeState({
            ...t.themeState,
            navPrimaryColor: '#FFFFFF',
            navTextColor: '#000000'
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ backgroundImage: `url('${letterImg}')` }} className='image-container-letter'>
            <div className='text-box'>
                <h1>{letter.title}</h1>
                <div className='letter-main'>
                    <Markdown>
                        {letter.text}
                    </Markdown>
                </div>
                <div className='footer'>
                    <h2><strong>{letter.editors[0]}</strong>{letter.editors[1]}</h2>
                    <h2><strong>{letter.editors[2]}</strong></h2>
                    <h2><strong>{letter.editors[3]}</strong>{letter.editors[4]}</h2>
                </div>
            </div>
        </div>
    );
}