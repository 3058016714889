import Search from './Search';
import { useState, useRef } from 'react';
import DropdownMenu from './DropdownMenu';
import { useMenuCloseEvent } from '../util/Hooks';
import { Menu } from "lucide-react";
import { VarsityLogo } from "./VarsityLogo";
import { useTheme } from "./ThemeContext";
import { CustomCSS } from "../types/CustomCSS";
import "./HoverAnimation.css";

export default function Navigation() {
    const [menuTextFocused, setMenuTextFocused] = useState(false);

    const menuRef = useRef(null);
    const menuTextRef = useRef(null);

    useMenuCloseEvent([menuRef, menuTextRef], () => {
        if (menuTextFocused) {
            setMenuTextFocused(false);
        }
    });

    const t = useTheme();

    return (
        <nav className="fixed top-0 left-0 flex h-16 px-4 w-full bg-[var(--current-background)] text-[var(--current-text)] z-[1000] text-lg"
             style={{'--current-background': t.themeState.navPrimaryColor,
                     '--current-text': t.themeState.navTextColor,
                     '--current-hover-background': t.themeState.navTextColor,
                     '--current-hover-text': t.themeState.navPrimaryColor
             } as CustomCSS}>
            <div className="flex flex-row justify-between w-full h-full md:grid md:grid-cols-3">
                <div className="flex gap-4 items-center justify-start w-fit ">
                    <button 
                            ref={menuTextRef} 
                            className={"relative h-fit px-2 py-2 hover-animation transition-[width] duration-300 " 
                                       + (menuTextFocused ? 'selected md:w-[21rem]' : 'md:w-28')}
                            aria-label='Menu'
                            onClick={() => {
                                setMenuTextFocused(true);
                            }}
                            onFocus={() => {
                                setMenuTextFocused(true);
                            }}
                            onBlur={(e) => {
                                if (!e.currentTarget.contains(e.relatedTarget)) {
                                    setMenuTextFocused(false);
                                }
                            }}
                        >
                        <div className='flex items-center gap-x-2'>
                            <Menu className="size-8 md:size-6"></Menu>
                            <span className="hidden md:block">MENU</span>
                        </div>
                        {
                            menuTextFocused && (
                                <DropdownMenu ref={menuRef} className='-left-4 w-screen md:w-full'></DropdownMenu>
                            )
                        }
                    </button>
                </div>
                <div className="flex gap-4 items-center justify-center">
                    <button className="flex items-center gap-2 h-fit px-2 py-1 hover-animation"
                            onClick={() => {
                                window.scrollTo({top: 0, behavior: 'smooth'});
                            }}>
                        <VarsityLogo className="size-8"></VarsityLogo>
                        <span className="font-semibold text-2xl hidden sm:block">THE VARSITY</span>
                    </button>
                </div>
                <div className="flex gap-4 items-center justify-end">
                    <Search></Search>
                </div>
            </div>
        </nav>
    );
}