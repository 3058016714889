import React from 'react';
import {useContext} from 'react';
import {ThemeContextType, ThemeProviderProps} from '../types/ThemeContext';

const ThemeContext = React.createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
    const theme = useContext(ThemeContext);
    if (!theme) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return theme;
};

export const ThemeProvider = ({children}: ThemeProviderProps) => {
    const [themeProps, setThemeProps] = React.useState({
        navPrimaryColor: 'var(--primary)',
        navTextColor: 'var(--secondary)',
        navInverseColor: 'var(--inverse)'
    });

    return (
        <ThemeContext.Provider value={{themeState: themeProps, setThemeState: setThemeProps}}>
            {children}
        </ThemeContext.Provider>
    );
};
