import React, { useEffect } from 'react';
import credImg from './Credits/CREDITS-WEB.jpg';
import './Credits/Credits.css';
import {CreditsType} from '../types/CreditsType'
import { useTheme } from './ThemeContext';

export default function Credits({credits}: {credits: CreditsType}) {
    const t = useTheme();
    useEffect(() => {
        t.setThemeState({
            ...t.themeState,
            navPrimaryColor: '#FFFFFF',
            navTextColor: '#000000'
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <div className='image-container-credits'>
                <img src={credImg} alt="Credits"/>
                <div className='credits-header'>
                    <h1>CREDITS</h1>
                </div>
            </div>
            <div className='credits-container'>
                <div className="line-credits-container">
                    <div className='line-credits'>
                        <h2 className='credits-articles-line'>ARTICLES</h2>
                        <ul className='credits-left'>
                        {credits.article_credits.map((person, index) => {
                            return (
                                <li className='credits-line' key= {index}><span style={{fontWeight: "bold"}}>{person.name}</span>{person.showPosition ? `, ${person.position}` : ''}</li>
                            );
                        })}
                        </ul>
                    </div>
                    <div className="credits-vertical-line"></div>
                    <div className='line-credits'>
                        <h2 className='credits-visuals-line'>VISUALS</h2>
                        <ul className='credits-right'>
                        {credits.visual_credits.map((person, index) => {
                            return (
                                <li className='credits-line' key= {index}><span style={{fontWeight: "bold"}}>{person.name}</span>{person.showPosition ? `, ${person.position}` : ''}</li>
                            );
                        })}
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}