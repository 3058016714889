import React from 'react';
import InteractiveMap from '../components/InteractiveMap';
import ImageCredit from '../components/ImageCredit';

const MapTest = () => {
    return (
        <div className='relative mt-16 h-[calc(100vh-4rem)] w-full'>
            <ImageCredit name='Map Illustration: Vicky Huang'></ImageCredit>
            <InteractiveMap waypoints={[
                {
                    x: 48,
                    y: 82.5,
                    title: "Places of worship and religious and spiritual associations at U of T",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/places-of-worship-and-religious-and-spiritual-associations-at-u-of-t"
                },
                {
                    x: 65,
                    y: 83,
                    title: "Housing tips for finding a place in Toronto",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/housing-tips-for-finding-a-place-in-toronto"
                },
                {
                    x: 75,
                    y: 78,
                    title: "Socializing from Scratch",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/socializing-from-scratch"
                },
                {
                    x: 60,
                    y: 62,
                    title: "Sexual health care at U of T",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/sexual-health-care-at-u-of-t"
                },
                {
                    x: 42,
                    y: 66,
                    title: "Mental, physical, and academic resources for every U of T student",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/mental-physical-and-academic-resources-for-every-u-of-t-student"
                },
                {
                    x: 38,
                    y: 54.5,
                    title: "How physical activity can improve your academic performance",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/how-physical-activity-can-improve-your-academic-performance"
                },
                {
                    x: 13,
                    y: 29.5,
                    title: "A Guide to Navigating Long-Distance Friendships",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/a-guide-to-navigating-long-distance-friendships"
                },
                {
                    x: 130,
                    y: 82.5,
                    title: "Cultural hotspots in Toronto",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/cultural-hotspots-in-toronto"
                },
                {
                    x: 135,
                    y: 79.75,
                    title: "The best Toronto date spots for university students",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/the-best-toronto-date-spots-for-university-students"
                },
                {
                    x: 130,
                    y: 74.25,
                    title: "Cheap, Tasty, and quality food around campus",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/cheap-tasty-and-quality-food-around-campus"
                },
                {
                    x: 135,
                    y: 45,
                    title: "Ways to get involved creatively at U of T",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/ways-to-get-involved-creatively-at-u-of-t"
                },
                {
                    x: 149.5,
                    y: 63,
                    title: "A guide on how to navigate U of T's research and employment landscape",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/a-guide-on-how-to-navigate-u-of-t-research-and-employment-landscape"
                },
                {
                    x: 153,
                    y: 77,
                    title: "Getting Involved with The Varsity",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/getting-involved-with-the-varsity"
                },
                {
                    x: 160,
                    y: 72.5,
                    title: "An overview of some of U of T St. George's libraries",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/an-overview-of-some-of-u-of-t-st-georges-libraries"
                },
                {
                    x: 197,
                    y: 52,
                    title: "Important news stories",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/important-news-stories"
                },
                {
                    x: 187,
                    y: 45,
                    title: "Important People at U of T",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/important-people-at-u-of-t"
                },
                {
                    x: 200,
                    y: 80,
                    title: "A guide to teams to watch and events to attend in Toronto",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/a-guide-to-teams-to-watch-and-events-to-attend-in-toronto"
                },
                {
                    x: 233,
                    y: 67,
                    title: "I make time to go outside, and you should too!",
                    primaryColour: "red",
                    secondaryColour: "blue",
                    description: "Extra stuff",
                    link: "/article/i-make-time-to-go-outside-you-should-too"
                },
            ]} />
        </div>
    );
}

export default MapTest;