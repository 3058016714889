import { useEffect } from "react";

export function useMenuCloseEvent(refs: React.RefObject<any>[], onCloseCallback: () => void) {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleRefocus(event: any) {
      for (const ref of refs) {
        if (ref.current && ref.current.contains(event.target)) {
          return;
        }
      }
      onCloseCallback();
    }

    // Bind
    document.addEventListener("mousedown", handleRefocus);
    return () => {
      // dispose
      document.removeEventListener("mousedown", handleRefocus);
    };
  }, [refs, onCloseCallback]);
}