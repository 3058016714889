import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import './index.css';
import Credits from './components/Credits';
import {creditsData} from './assets/articles/credits';
import EditorLetter from './components/EditorLetter';
import {letterData} from './assets/articles/letter';
import MapPage from './pages/MapPage';
import ArticlePage from './pages/[ArticlePage]';
import { ThemeProvider } from './components/ThemeContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const Index = () => {
  return <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <Navigation />
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
          </Route>

          <Route path="/map">
            <Route index element={<MapPage />} />
          </Route>

          {/* did not add all credits */}
          <Route path="/credits">
            <Route index element={<Credits credits={creditsData} />} />
          </Route>

          <Route path="/letter">
            <Route index element={<EditorLetter letter={letterData} />} />
          </Route>

          { /* Route all paths from /article/{slug} to the ArticlePage component with the slug as a prop */}
          <Route path="/article/:slug" element={<ArticlePage />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>;
};


root.render(<Index />);