import { CarouselEntry } from "../../types/CarouselTypes";

import newsImg1 from '../../assets/images/NEWS-news-stories-color.jpg'
import newsImg2 from '../../assets/images/NEWS-Important-people-KAISA-KASEKAMP-web.jpg'
import newsImg3 from '../../assets/images/NEWS-Sexual-healthcare-NILIMA-PAUL-web.jpg'

import artsImg1 from '../../assets/images/ARTS-Healthy-Tasty_Jishna-Sunkara THEVARSITY.jpg'
import artsImg2 from '../../assets/images/ARTS-Cultural-spots-in-Toronto-KAISA KASEKAMP_THEVARSITY-web.jpg'
import artsImg3 from '../../assets/images/ARTS-Get-Involved-Creatively-VARIOUS-ARTISTS.jpg'

import sciImg1 from '../../assets/images/SCIENCE-Mental_Physical_Health- ElenaOsipyan.jpg'
import sciImg2 from '../../assets/images/SCIENCE-socializing-tips-KAISA KASEKAMP-photo1.jpg'
import sciImg3 from '../../assets/images/SCIENCE-spending-time-in-nature-COLLAGE.jpg'

import busImg1 from '../../assets/images/BUSINESS-Internships research jobs-KAISA-Photo-for-article-selection.jpg'
import busImg2 from '../../assets/images/BUSINESS_Networking-Tips-Illo_Evelyn-Bolton.jpg'
import busImg3 from '../../assets/images/BUSINESS_Housing-Resources_VICKYHUANG THEVARSITY.jpg'

import sportsImg1 from '../../assets/images/SPORTS-Getting-active-in-school.jpg'
import sportsImg2 from '../../assets/images/SPORTS-getting-involved-sports-entertainment-KAISA KASEKAMP.jpg'

import opImg1 from '../../assets/images/OPINION-Best_Libraries_VICKYHUANG_THEVARSITY copy.jpg'
import opImg2 from '../../assets/images/FEATURES-Places_of_worship_BiewBiewSakulwannadee_THEVARSITY copy.jpg'
import opImg3 from '../../assets/images/OPINION-long-distance-relationships-KAISA KASEKAMP_THEVARSITY.jpg'
import opImg4 from '../../assets/images/OPINION-First-date-spots-Toronto.jpg'
import opImg5 from '../../assets/images/opinion-course-load-VICKY HUANG_THEVARSITY.jpg'

const news_section = [
    {
        title: 'Important news stories',
        alt_name: 'news stories',
        image: newsImg1,
        link: '/article/important-news-stories'
    },
    {
        title: 'Important people at U of T',
        alt_name: 'important people',
        image: newsImg2,
        link: '/article/important-people-at-u-of-t'
    },
    {
        title: 'Sexual health care at U of T',
        alt_name: 'sexual health care',
        image: newsImg3,
        link: '/article/sexual-health-care-at-u-of-t'
    }
] as CarouselEntry[];

const arts_section = [
    {
        title: 'Cheap, tasty, and quality food around campus',
        alt_name: 'food',
        image: artsImg1,
        link: '/article/cheap-tasty-and-quality-food-around-campus'
    },
    {
        title: 'Cultural spots in Toronto',
        alt_name: 'cultural spots',
        image: artsImg2,
        link: '/article/cultural-hotspots-in-toronto'
    },
    {
        title: 'Ways to get involved creatively at U of T',
        alt_name: 'creative involvement',
        image: artsImg3,
        link: '/article/ways-to-get-involved-creatively-at-u-of-t'
    }
] as CarouselEntry[];

const sci_section = [
    {
        title: 'Mental, physical, and academic resources for every U of T student',
        alt_name: 'resources',
        image: sciImg1,
        link: '/article/mental-physical-and-academic-resources-for-every-u-of-t-student'
    },
    {
        title: 'Socializing from Scratch',
        alt_name: 'socializing',
        image: sciImg2,
        link: '/article/socializing-from-scratch'
    },
    {
        title: 'I make time to go outside, and you should too!',
        alt_name: 'outside time',
        image: sciImg3,
        link: '/article/i-make-time-to-go-outside-you-should-too'
    }
] as CarouselEntry[];

const bus_section = [
    {
        title: 'A guide on how to navigate U of T’s research and employment landscape',
        alt_name: 'research and employment',
        image: busImg1,
        link: '/article/a-guide-on-how-to-navigate-u-of-t-research-and-employment-landscape'
    },
    {
        title: 'Networking in your undergrad',
        alt_name: 'networking',
        image: busImg2,
        link: '/article/networking-in-your-undergrad'
    },
    {
        title: 'Housing tips for finding a place in Toronto',
        alt_name: 'housing',
        image: busImg3,
        link: '/article/housing-tips-for-finding-a-place-in-toronto'
    }
] as CarouselEntry[];

const sports_section = [
    {
        title: 'How physical activity can improve your academic performance',
        alt_name: 'physical activity',
        image: sportsImg1,
        link: '/article/how-physical-activity-can-improve-your-academic-performance'
    },
    {
        title: 'A guide to teams to watch and events to attend in Toronto',
        alt_name: 'teams and events',
        image: sportsImg2,
        link: '/article/a-guide-to-teams-to-watch-and-events-to-attend-in-toronto'
    }
] as CarouselEntry[];

const op_section = [
    {
        title: 'An overview of some of U of T St George’s libraries',
        alt_name: 'libraries',
        image: opImg1,
        link: '/article/an-overview-of-some-of-u-of-t-st-georges-libraries'
    },
    {
        title: 'Places of worship and religious and spiritual associations at U of T',
        alt_name: 'worship',
        image: opImg2,
        link: '/article/places-of-worship-and-religious-and-spiritual-associations-at-u-of-t'
    },
    {
        title: 'A Guide to Navigating Long-Distance Friendships',
        alt_name: 'long-distance friendships',
        image: opImg3,
        link: '/article/a-guide-to-navigating-long-distance-friendships'
    },
    {
        title: 'The best Toronto date spots for university students',
        alt_name: 'date spots',
        image: opImg4,
        link: '/article/the-best-toronto-date-spots-for-university-students'
    },
    {
        title: 'How to manage your course load',
        alt_name: 'course load',
        image: opImg5,
        link: '/article/how-to-manage-your-courseload'
    }
] as CarouselEntry[];

const sectionMap = {
    'NEWS': news_section,
    'ARTS & CULTURE': arts_section,
    'SCIENCE': sci_section,
    'BUSINESS': bus_section,
    'SPORTS': sports_section,
    'OPINION AND FEATURES': op_section
} as Record<string, CarouselEntry[]>;

export default sectionMap;