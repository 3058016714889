import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArticleMetadataType from '../types/ArticleMetadata';
import Markdown, { defaultUrlTransform } from 'react-markdown';
import './[ArticlePage]/[ArticlePage].css';
import { useTheme } from '../components/ThemeContext';
import { Circle } from 'lucide-react';

export default function ArticlePage() {
    // Get slug from URL
    const { slug } = useParams();
    const [articleBody, setArticleBody] = React.useState<string | null>(null);
    const [articleMetadata, setArticleMetadata] = React.useState<ArticleMetadataType | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<number | null>(null);
    const [featuredImage, setFeaturedImage] = React.useState<string | null>(null);
    const navigate = useNavigate();

    const t = useTheme();

    // First load
    useEffect(() => {
        // Look for file in ../assets/articles/{slug}.md

        const findArticle = async () => {
            try {
                let articlePath = require(`../assets/articles/${slug}.md`);
                let metadata : ArticleMetadataType = require(`../assets/articles/${slug}.json`);
                let featuredImage = require(`../assets/images/${metadata.featuredImage}`);

                // Fetch article
                let articleResponse = await fetch(articlePath);
                let articleText = await articleResponse.text();

                console.log(articleResponse)

                setArticleMetadata(metadata);
                setArticleBody(articleText);
                setFeaturedImage(featuredImage);
                
                let currentThemeState = {
                    ...t.themeState
                };

                if (metadata.primaryColor) {
                    currentThemeState.navPrimaryColor = metadata.primaryColor;
                }

                if (metadata.textHighlightColor) {
                    currentThemeState.navTextColor = metadata.textHighlightColor;
                } else {
                    currentThemeState.navTextColor = "white";
                }

                t.setThemeState(currentThemeState);
            } catch (error) {
                console.log(error);
                setError(404);
                navigate('/#articles');
            } finally {
                setLoading(false);
            }
        };

        findArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    if (error) {
        return <h1 className='mt-16 text-4xl w-full text-center p-10'>Error {error}</h1>;
    }

    if (loading) {
        return <p>Loading...</p>;
    }

    const textHighlightColor = articleMetadata?.textHighlightColor ? articleMetadata.textHighlightColor : (articleMetadata?.primaryColor ? articleMetadata.primaryColor : "black");

    // Render article
    return (
        <div className='mt-16'>
            <div className="relative article-featured-image-container">
                { featuredImage && 
                    <img src={featuredImage} className="article-featured-image" alt={articleMetadata?.hed} /> 
                }
                {
                    featuredImage && articleMetadata && articleMetadata.imageCredit &&
                    // split imageCredit on \n
                    <div className='absolute bottom-0 right-0 text-lg p-2 mb-4 mr-4 bg-white rounded-lg text-right'>
                        {articleMetadata.imageCredit.split('\n').map((credit, index) => (
                            <p key={index}>{credit}</p>
                        ))}
                    </div>
                }
            </div>

            <div className="article-body-container" style={{ backgroundColor: articleMetadata?.primaryColor ? articleMetadata.primaryColor : "gray" }}>
                <div className="article-body">
                    <div className="article-heading-container" style={{ color: textHighlightColor }}>
                        <h1 className="article-heading">{articleMetadata?.hed}</h1>
                        <h2 className="article-byline">By: <span style={{ color: "black" }}>{articleMetadata?.byline}</span></h2>
                    </div>
                    <Markdown
                        urlTransform={(url) => (url.indexOf(":") !== -1 ? url : defaultUrlTransform(url))}
                        className="article-body-content"
                        components={{
                            /* eslint-disable jsx-a11y/heading-has-content */
                            /* eslint-disable jsx-a11y/anchor-has-content */
                            h1: ({node, ...props}) => <h1 style={{ color: textHighlightColor }} {...props} />,
                            // @eslint-disable-next-line
                            h2: ({node, ...props}) => <h2 style={{ color: textHighlightColor }} {...props} />,
                            // @eslint-disable-next-line
                            h3: ({node, ...props}) => <h3 style={{ color: textHighlightColor }} {...props} />,
                            // @eslint-disable-next-line
                            h4: ({node, ...props}) => <h4 style={{ color: textHighlightColor }} {...props} />,
                            // @eslint-disable-next-line
                            h5: ({node, ...props}) => <h5 style={{ color: textHighlightColor }} {...props} />,
                            // @eslint-disable-next-line
                            h6: ({node, ...props}) => <h6 style={{ color: textHighlightColor }} {...props} />,
                            a: ({node, ...props}) => <a style={{ color: textHighlightColor }} target='_blank' rel='noreferrer noopener' {...props}/>,
                            // add a little circle beside list items using pseudoclass
                            li: ({node, ...props}) => (
                                <li {...props} className='my-4'>
                                    <Circle className='inline-block mr-2' size={16} />
                                    {props.children}
                                </li>
                            )
                            /* eslint-enable jsx-a11y/heading-has-content */
                            /* eslint-enable jsx-a11y/anchor-has-content */
                        }}
                    >{articleBody}</Markdown>
                </div>
            </div>

        </div>
    );
}