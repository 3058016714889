import CoverImageMobile from '../assets/images/mobile view/MOBILE-Cover-web-NO-TEXT-2.jpg';
import CoverBackground from '../assets/images/COVER-Background.png';
import CoverForeground from '../assets/images/COVER-Foreground.png';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function HeroImage({ mobile }: { mobile: boolean }) {
    const containerRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLHeadingElement>(null);
    const title2Ref = useRef<HTMLHeadingElement>(null);
    const title3Ref = useRef<HTMLHeadingElement>(null);
    const coverDivRef = useRef<HTMLDivElement>(null);
    const foregroundRef = useRef<HTMLDivElement>(null);
    const foregroundRef2 = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (mobile) {
            return;
        }

        gsap.registerPlugin(ScrollTrigger);

        const container = containerRef.current;
        const title = titleRef.current;
        const title2 = title2Ref.current
        const foreground = foregroundRef.current;
        const foreground2 = foregroundRef2.current;

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: container,
                start: 'top top',
                end: 'bottom top-=4rem',
                scrub: -2,
                pin: false,
            },
        });

        tl.fromTo(title,
            { opacity: 1, y: 0, scale: 1 },
            { opacity: 0.5, y: 450, scale: 0.8, duration: 2 }
        )
        .fromTo(title2,
            { opacity: 0, y: 600, scale: 1 },
            { opacity: 1, y: -500, scale: 1.25, duration: 1.25 }, '<'
        )
        .fromTo(foreground,
            { opacity: 1, y: 0, scale: 1, x: 0 },
            { opacity: 1, y: 0, scale: 1.1, x: -100, duration: 0.25 }, '<'
        )
        .fromTo(foreground2,
            { opacity: 1, y: 0, scale: 1, x: 0 },
            { opacity: 1, y: 0, scale: 1.1, x: 100, duration: 0.25 }, '<'
        )
        .fromTo(foreground,
            { opacity: 1, y: 0, scale: 1.1, x: -100 },
            { opacity: 1, y: 0, scale: 1.2, x: -140, duration: 1.5 }, '>'
        )
        .fromTo(foreground2,
            { opacity: 1, y: 0, scale: 1.1, x: 100, },
            { opacity: 1, y: 0, scale: 1.2, x: 140, duration: 1.5 }, '<'
        )
        ;

        return () => {
            if (tl) tl.kill();
        };
    }, [mobile]);

    return (
        // Let part of the bottom of the image overflow so it looks better.
        <div className="relative pt-16 h-[calc(100vh)] w-full -z-10 overflow-hidden" id='hero-image' ref={containerRef}>
            <div className={"relative h-screen bg-cover bg-center bg-no-repeat"}
                style={{ backgroundImage: `url(${mobile ? CoverImageMobile : CoverBackground})` }}
                ref={coverDivRef}>
                <div className={`absolute inset-0 px-10 z-20 ${mobile ? 'hidden' : ''}`}>
                    <h1 className='fixed top-[calc(4rem+6vh)] text-[10.75vw] text-[var(--yellow-300)] font-bold w-full leading-[8vw] overflow-hidden'
                        id='handbook-title' ref={titleRef}>HANDBOOK 2024</h1>
                </div> 
                <div className={`absolute inset-0 px-10 z-30 ${mobile ? 'hidden' : ''}`}>
                    <h1 className='fixed top-[calc(4rem+6vh)] text-[10.75vw] text-[var(--yellow-300)] font-bold w-full leading-[8vw] overflow-hidden'
                        id='handbook-title-2' ref={title2Ref}>HANDBOOK 2024</h1>
                </div>
                <div className={`absolute inset-0 px-10 z-10 ${mobile ? 'hidden' : ''}`}>
                    <h1 className='fixed top-[calc(4rem+6vh)] text-[10.75vw] text-[var(--yellow-300)] font-bold w-full leading-[8vw] overflow-hidden'
                        id='handbook-title-3' ref={title3Ref}>HANDBOOK 2024</h1>
                </div>
                <div className={`absolute inset-0 bg-cover bg-center bg-no-repeat z-[25] ${mobile ? 'hidden' : ''}`}
                    style={{ backgroundImage: `url(${CoverForeground})` }}
                    ref={foregroundRef}
                >
                </div>
                <div className={`absolute inset-0 bg-cover bg-center bg-no-repeat z-[15] ${mobile ? 'hidden' : ''}`}
                    style={{ backgroundImage: `url(${CoverForeground})` }}
                    ref={foregroundRef2}
                >
                </div>
                <div className={`absolute inset-0 z-20 ${mobile ? '' : 'hidden'} overflow-hidden`}>
                    <h1 className='relative top-4 right-4 text-right text-[14vw] text-[var(--yellow-300)] font-bold w-full leading-[12vw] '
                        id='handbook-title-mobile'>STUDENT <br></br> HANDBOOK <br></br> 2024</h1>
                </div> 
            </div>
        </div>
    );
}