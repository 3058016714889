import { forwardRef, ForwardedRef } from 'react';
import { CustomCSS } from '../types/CustomCSS';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface DropdownButtonProps {
  url: string;
  text: string;
  external?: boolean;
}

function DropdownButton({url, text, external=false} : DropdownButtonProps) {
  return (
    <Link to={url} target={external ? "_blank" : "_self"} rel={external ? "noreferrer" : ""} className="hover-animation px-2 py-1 line-clamp-1 text-left">
      {text}
    </Link>
  );
}

interface DropdownMenuProps {
  className?: string;
}

const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(({className} : DropdownMenuProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div ref={ref} className={clsx(className, 'absolute top-[3.5rem] md:top-9 md:left-0 bg-white w-full '+
                              'p-6 flex flex-col gap-y-2 font-semibold items-start text-lg ' +
                              'shadow-black')}
         style={{'--current-background': "white",
                 '--current-text': "black",
                 '--current-hover-background': "black", 
                 '--current-hover-text': "var(--yellow-300)"
         } as CustomCSS}>
      <DropdownButton url="/#top" text="HOME"></DropdownButton>
      <DropdownButton url="/#articles" text="ARTICLES"></DropdownButton>
      <DropdownButton url="/#map" text="UOFT MAP"></DropdownButton>
      <DropdownButton url="/letter" text="LETTERS FROM THE EDITORS"></DropdownButton>
      <DropdownButton url="https://thevarsity.ca" text="THE VARSITY" external></DropdownButton>
    </div>
  );
});

export default DropdownMenu;