import React, { useEffect } from 'react';
import AdviceSection from '../components/AdviceSection';
import ArticleCarousel from '../components/ArticleCarousel';
import GetInvolvedFeatureImage from '../assets/images/Get-involved-with-the-varsity-BANNER-HOME.jpg';
import InteractiveMapImage from '../assets/images/U-of-T-Map.jpg';
import { Link, useLocation } from 'react-router-dom';
import HeroImage from '../components/HeroImage';
import { CustomCSS } from '../types/CustomCSS';

export default function Home() {
    const [mobile, setMobile] = React.useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        const mobileResizer = () => {
            let deviceOrientation = window.innerWidth < window.innerHeight ? 'portrait' : 'landscape';
            setMobile(window.innerWidth <= 768 || deviceOrientation === 'portrait');
        }

        window.addEventListener('resize', mobileResizer);
        mobileResizer();

        return () => {
            window.removeEventListener('resize', mobileResizer);
        }
    }, []);

    // Scroll to the correct anchor if the URL has a hash
    useEffect(() => {
        if (location.hash) {
            const anchor = document.querySelector(location.hash);
            if (anchor) {
                anchor.scrollIntoView();
            }
        }
    }, [location.hash]);

    return (
        <div className='w-full'>
            { /* Featured image */}
            <section id="top" />
            <HeroImage mobile={mobile}></HeroImage>

            { /* This is not a completed section of cards; the content will follow later */}
            <section id="advice" />
            <AdviceSection />

            <section id="articles" />
            <ArticleCarousel isMobile={mobile} />

            <section id="get-involved" />
            <div className="flex flex-col md:flex-row">
                <div className="w-full">
                    <div className="bg-gray-200 w-full h-screen flex items-center justify-center overflow-hidden p-10" 
                        style={{ backgroundImage: `url(${GetInvolvedFeatureImage})`, 
                                 backgroundSize: 'cover', 
                                 backgroundPosition: 'center',
                                 '--current-text': "white",
                                 '--current-hover-background': "var(--yellow-300)",
                                 '--current-hover-text': "black"
                               } as CustomCSS}>
                        <Link 
                            to='/article/getting-involved-with-the-varsity'
                            className="relative hover-animation text-4xl md:text-6xl text-yellow font-bold text-center bg-black p-4"
                        >GET INVOLVED WITH THE VARSITY
                            <h2 className='absolute top-[-3rem] right-0 xl:-right-10 p-3 text-2xl md:text-3xl font-semibold bg-white text-black'>Read Article</h2>
                        </Link>
                    </div>
                </div>
            </div>
            <section id="map" />
            <div className="flex flex-col md:flex-row">
                <div className="w-full">
                    {/* Same as previous section, but with InteractiveMapImage. We need to apply 50% opacity to the background image, but not the text */}

                    <div className="bg-gray-200 w-full h-screen flex items-center justify-center relative overflow-hidden p-10">
                        <img src={InteractiveMapImage} alt="Interactive map of U of T" className="w-full z-0 h-full object-cover opacity-50 absolute inset-x-0 inset-y-0" />

                        <Link 
                            to='/map'
                            className="relative hover-animation text-4xl md:text-6xl text-black font-bold z-10 text-center bg-[var(--yellow-300)] p-4"
                            style={{
                                '--current-text': "black",
                                '--current-hover-background': "black",
                                '--current-hover-text': "var(--yellow-300)"
                            } as CustomCSS}
                        >EXPLORE U OF T
                            <span className='absolute top-[-3rem] right-0 md:-right-10 bg-black p-3 text-3xl font-semibold text-white'>View Map</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};