import './AdviceSection/AdviceSection.css';
import React from 'react';
import { useState } from 'react';
import { AdviceCardType } from '../types/AdviceCardType';
import AdviceCard from './AdviceCard';

const NavArrow = require('./AdviceSection/nav-arrow.svg');

export default function AdviceSection() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showLeftNav, setShowLeftNav] = useState(false);
    const [showRightNav, setShowRightNav] = useState(true);

    const adviceCards : AdviceCardType[] = [
        {
            content: 'Balance is key! It’s easy to fall into a trap of constantly studying, but UofT has a lot of other amazing opportunities! Join a club or student organization, meet with your professors and friends, and explore the city! Education is important, but having a well rounded university experience will make all the difference when you graduate.',
            author: 'Isabella Reny',
            position: 'Deputy Senior Copy Editor',
            bgColor: '#E9272C',
            textColor: '#FFFFFF'
        },
        {
            content: 'Don’t be afraid of love! Also attend your tutorials!',
            author: 'Divine Angubua',
            position: 'Arts & Culture Editor',
            bgColor: '#56A355',
            textColor: '#FFFFFF'
        },
        {
            content: 'We all might feel the need to posture, acting like we know a lot more than we do. But none of us - from first years to postgraduates to professors - truly know much. And that’s okay. In fact, that’s precisely what university is for: communal learning, questioning, revision, and growth. Besides: if you’re smart enough to trick everyone into thinking you deserve to be here, who’s to say you don’t?',
            author: 'Ajeetha Vithiyananthan',
            position: 'Managing Editor, internal',
            bgColor: '#0154A1',
            textColor: '#FFFFFF'
        },
        {
            content: 'Varsity Blues games are free for all students. Don’t let TMU out cheer us!',
            author: 'Jake Takeuchi',
            position: 'Sports Editor',
            bgColor: 'var(--yellow-300)',
            textColor: '#0154A1'
        },
        {
            content: 'Make sure to take notes by hand rather than on your laptop during lectures. Doing so will keep you more engaged and help you retain information better.',
            author: 'Rubin Beshi',
            position: 'Business & Labour Editor',
            bgColor: '#E9272C',
            textColor: 'white'
        },
        {
            content: 'Eat well.',
            author: 'Eleanor Yuneun Park',
            position: 'Editor-in-Chief',
            bgColor: '#56A355',
            textColor: 'white'
        },
        {
            content: 'If you’re stuck on a writing assignment, take your work to a writing centre. The instructors are really helpful in guiding you through the planning, organizing, or reviewing stage of any paper.',
            author: 'Selia Sanchez',
            position: 'News Editor',
            bgColor: '#0154A1',
            textColor: 'white'
        },
        {
            content: 'Get enough sleep, join a club, and don’t be scared to talk to new people. Everyone is just as lost, and it is always worth a try.',
            author: 'Medha Surajpal',
            position: 'Science Editor',
            bgColor: '#56A355',
            textColor: 'white'
        },
        {
            content: 'Changing programs and courses is more flexible than you might think! Don’t lock yourself into studying what you aren’t super interested in because of pressure from others who seem like they’re ahead on paper. There’s also no shame in taking more time to graduate if it means you have a better time with what you’re studying (or for any other reason).',
            author: 'Maeve Ellis',
            position: 'Managing Online Editor',
            bgColor: '#E9272C',
            textColor: 'white'
        },
        {
            content: 'It’s totally normal to have imposter syndrome but just know that you’ve worked so hard to get here and you totally deserve to be a student at UofT! Keep your head up.',
            author: 'Charmaine Yu',
            position: 'Opinion Editor',
            bgColor: 'var(--yellow-300)',
            textColor: '#0154A1'
        }
    ]

    const onNav = (direction: 'left' | 'right') => {
        const container = document.querySelector('.advice-card-container');
        if (container) {
            const cardWidth = 30 * 16;
            const scrollAmount = direction === 'left' ? -cardWidth : cardWidth;

            if (direction === 'left' && scrollPosition <= 0) {
                return;
            }

            if (direction === 'right' && scrollPosition >= container.scrollWidth - container.clientWidth) {
                return;
            }

            container.scrollTo({
                left: scrollPosition + scrollAmount,
                behavior: 'smooth'
            });
            setScrollPosition(scrollPosition + scrollAmount);
        }
    }

    const onScrollNavControl = (scrollAmount: number) => {
        if (scrollAmount <= 0) {
            setShowLeftNav(false);
        } else {
            setShowLeftNav(true);
        }

        const container = document.querySelector('.advice-card-container');
        if (container) {
            if (scrollAmount >= container.scrollWidth - container.clientWidth) {
                setShowRightNav(false);
            } else {
                setShowRightNav(true);
            }
        }

        setScrollPosition(scrollAmount);
    }

    return (
        <div className='relative'>
            <div className="advice-section-header-mobile">
                <h1>MASTHEAD ADVICE</h1>
            </div>
            <div className={`advice-section-nav advice-section-nav-left ${showLeftNav ? "advice-show-nav" : "advice-hide-nav"}`} onClick={() => onNav('left')}><img src={NavArrow.default} alt="Left" /></div>
            <div className={`advice-section-nav advice-section-nav-right ${showRightNav ? "advice-show-nav" : "advice-hide-nav"}`}  onClick={() => onNav('right')}><img src={NavArrow.default} alt="Left" /></div>
            <div className="advice-section">
                <div className="relative advice-card-container" onScroll={(e) => onScrollNavControl((e.target as HTMLDivElement).scrollLeft)}>
                    <div className="advice-section-bg z-10">
                        <div className="advice-section-header">
                            <h1>MASTHEAD <br />ADVICE</h1>
                        </div>
                    </div>
                    {adviceCards.map((adviceCard, index) => (
                        <AdviceCard key={index} adviceCard={adviceCard} />
                    ))}
                </div>
            </div>
        </div>
    );
}