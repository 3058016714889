import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageOverlay, MapContainer, Marker, Popup } from 'react-leaflet';
import InteractiveMapImage from '../assets/InteractiveMap/map.jpg';
import MapPinIcon from '../assets/InteractiveMap/map_pin.png';
import MapPinIconActive from '../assets/InteractiveMap/map_pin_active.png';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import { Waypoint } from '../types/Waypoint';
import { InteractiveMapProps } from '../types/InteractiveMapProps';
import "./InteractiveMap.css";
import { Link } from 'react-router-dom';

const WaypointMarker = ({ waypoint }: { waypoint: Waypoint }) => {
    return (<Marker position={[waypoint.y, waypoint.x]} icon={new L.Icon({
                iconUrl: MapPinIcon,
                iconSize: [25, 25],
                iconAnchor: [12.5, 25],
                popupAnchor: [0, -25],
            })}
            eventHandlers={
                {
                    mouseover: (e) => {
                        // Change icon
                        e.target.setIcon(new L.Icon({
                            iconUrl: MapPinIconActive,
                            iconSize: [25, 25],
                            iconAnchor: [12.5, 25],
                            popupAnchor: [0, -25],
                        }));
                    },

                    mouseout: (e) => {
                        // Change icon
                        e.target.setIcon(new L.Icon({
                            iconUrl: MapPinIcon,
                            iconSize: [25, 25],
                            iconAnchor: [12.5, 25],
                            popupAnchor: [0, -25],
                        }));
                    },

                    
                }
            }
    >
        <Popup>
            <div>
                <h2>{waypoint.title}</h2>
                <br />
                <hr />
                <br />
                {waypoint.link && <Link to={waypoint.link}>Read more</Link>}
            </div>
        </Popup>
    </Marker>);
}

const InteractiveMap = ({ waypoints }: InteractiveMapProps) => {
    const bounds = useMemo(() => {
        const t = [3840, 300] as L.LatLngTuple;
        const origin = [0, 0];
        return [origin, t] as [L.LatLngTuple, L.LatLngTuple]
    }, []);
    const mapRef = useRef<any>(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    const handleResize = useCallback(() => {
        if (mapRef.current) {
          const map = mapRef.current;
          map.invalidateSize();
          map.fitBounds(bounds);
        }
    }, [bounds]);

    // Resize map to fit bounds whenever the window resizes.
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        console.log("resizing");
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, [mapLoaded, handleResize]);

    return (
        <MapContainer
            style={{ height: "100%", width: "100%" }}
            bounds={bounds}
            boundsOptions={{
                padding: [0, 0]
            }}
            maxBounds={bounds}
            zoom={2}
            center={[150, 150]}
            zoomSnap={0} // Important to disable snap after fitBounds
            // Fit bounds on image ready
            ref={mapRef}
            whenReady={() => {
                setMapLoaded(true);
            }}
            
        >
            <ImageOverlay url={InteractiveMapImage} bounds={bounds}  />

            {waypoints.map((waypoint, index) => (
                <WaypointMarker key={index} waypoint={waypoint} />
            ))}
        </MapContainer>
    );
}

export default InteractiveMap;