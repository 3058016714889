import React, { useEffect } from 'react';

import {CarouselEntry, SectionProps, ArticleCarouselProps} from '../types/CarouselTypes';
import { Link } from 'react-router-dom';
import './ArticleCarousel/ArticleCarousel.css';
import sectionMap from '../components/ArticleCarousel/CarouselData';
import { CustomCSS } from '../types/CustomCSS';

function Section({ carouselEntries, section_name, isMobile }: SectionProps) {
    const [currentEntry, setCurrentEntry] = React.useState(carouselEntries[0]);

    useEffect(() => {
        if (isMobile) {
            const ret = setInterval(() => {
                    /* Cycle through carouselEntries */
                    const currentIndex = carouselEntries.indexOf(currentEntry);
                    const nextIndex = (currentIndex + 1) % carouselEntries.length;
                    setCurrentEntry(carouselEntries[nextIndex]);
            }, 3000);

            return () => clearInterval(ret);
        }
    }, [isMobile, currentEntry, carouselEntries]);

    const setCurrentEntryMiddle = (entry: CarouselEntry) => {
        if (!isMobile) setCurrentEntry(entry);
    }

    return (
        <div className="section-container">
            <div className="section-image-container">
                <img
                    className='section-image'
                    src={currentEntry.image}
                    alt={currentEntry.alt_name}
                />
                <h2 className='section-name-mobile line-clamp-1'>{section_name}</h2>
            </div>
            <div className="section-links-container">
                <div className="section-separator" />
                <ul className="section-links"
                    style={{'--current-text': "white",
                            '--current-hover-background': "var(--yellow-300)",
                            '--current-hover-text': "black"
                    } as CustomCSS}
                >
                    {carouselEntries.map((entry, index) => (
                        <li
                            key={index}
                            onMouseEnter={() => setCurrentEntryMiddle(entry)}
                            className={'hover-animation px-4 py-2 my-1 w-fit ' + (((entry === currentEntry) && isMobile) ? 'active' : '')}
                        >
                            <Link to={entry.link}>{entry.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='section-name-container'>
                <h2 className='section-name'>{section_name}</h2>
            </div>
        </div>
    );
}

export default function ArticleCarousel({ isMobile }: ArticleCarouselProps) {
    return (
        <div className="carousel-container">
            <h1 className='carousel-header'>ARTICLES</h1>
            {Object.entries(sectionMap).map(([section_name, carouselEntries]) => (
                <Section
                    key={section_name}
                    carouselEntries={carouselEntries}
                    section_name={section_name}
                    isMobile={isMobile}
                />
            ))}
        </div>
    );
}