type ArticleIndexEntry = {
    article_path: string;
    hed: string;
    byline: string;
};

const article_index = [
  {
    "article_path": "/article/socializing-from-scratch",
    "hed": "Socializing from Scratch",
    "byline": "Mehar Singh, Varsity Contributor"
  },
  {
    "article_path": "/article/mental-physical-and-academic-resources-for-every-u-of-t-student",
    "hed": "Mental, physical, and academic resources for every U of T student",
    "byline": "Medha Surajpal, Science Editor"
  },
  {
    "article_path": "/article/a-guide-to-teams-to-watch-and-events-to-attend-in-toronto",
    "hed": "A guide to teams to watch and events to attend in Toronto",
    "byline": "Jake Takeuchi, Sports Editor"
  },
  {
    "article_path": "/article/how-physical-activity-can-improve-your-academic-performance",
    "hed": "How physical activity can improve your academic performance",
    "byline": "Bruno Macia, Varsity Staff"
  },
  {
    "article_path": "/article/places-of-worship-and-religious-and-spiritual-associations-at-u-of-t",
    "hed": "Places of worship and religious and spiritual associations at U of T",
    "byline": "Sanjuktha Vimal, Varsity Contributor"
  },
  {
    "article_path": "/article/important-people-at-u-of-t",
    "hed": "Important people at U of T",
    "byline": "Simon Bettle, Varsity Contributor"
  },
  {
    "article_path": "/article/how-to-manage-your-courseload",
    "hed": "How to manage your course load",
    "byline": "Tarushi Sahni, Varsity Contributor"
  },
  {
    "article_path": "/article/getting-involved-with-the-varsity",
    "hed": "Getting Involved with The Varsity",
    "byline": "Isabella Reny, Deputy Senior Copy Editor"
  },
  {
    "article_path": "/article/cultural-hotspots-in-toronto",
    "hed": "Cultural hotspots in Toronto",
    "byline": "Druphadi Sen, Varsity Contributor"
  },
  {
    "article_path": "/article/important-news-stories",
    "hed": "Important news stories",
    "byline": "Selia Sanchez, News Editor"
  },
  {
    "article_path": "/article/sexual-health-care-at-u-of-t",
    "hed": "Sexual health care at U of T",
    "byline": "Olga (Olya) Fedossenko, Assistant News Editor"
  },
  {
    "article_path": "/article/a-guide-to-navigating-long-distance-friendships",
    "hed": "A Guide to Navigating Long-Distance Friendships",
    "byline": "Zulal Tunc, Varsity Contributor"
  },
  {
    "article_path": "/article/housing-tips-for-finding-a-place-in-toronto",
    "hed": "Housing tips for finding a place in Toronto",
    "byline": "Mari Khan, Varsity Contributor"
  },
  {
    "article_path": "/article/the-best-toronto-date-spots-for-university-students",
    "hed": "The best Toronto date spots for university students",
    "byline": "Sofia Tarnopolsky, Varsity Contributor"
  },
  {
    "article_path": "/article/an-overview-of-some-of-u-of-t-st-georges-libraries",
    "hed": "An overview of some of U of T St. George\u2019s libraries",
    "byline": "Sophie Esther Ramsey, Features Editor"
  },
  {
    "article_path": "/article/a-guide-on-how-to-navigate-u-of-t-research-and-employment-landscape",
    "hed": "A guide on how to navigate U of T\u2019s research and employment landscape",
    "byline": "Rubin Beshi, Business & Labour Editor"
  },
  {
    "article_path": "/article/cheap-tasty-and-quality-food-around-campus",
    "hed": "Cheap, tasty, and quality food around campus",
    "byline": "Ella Hamilton, Varsity Contributor"
  },
  {
    "article_path": "/article/i-make-time-to-go-outside-you-should-too",
    "hed": "I make time to go outside, and you should too!",
    "byline": "Alyssa Ukani, Varsity Contributor"
  },
  {
    "article_path": "/article/networking-in-your-undergrad",
    "hed": "Networking in your undergrad",
    "byline": "Jevan Konyar, Varsity Contributor"
  },
  {
    "article_path": "/article/ways-to-get-involved-creatively-at-u-of-t",
    "hed": "Ways to get involved creatively at U of T",
    "byline": "Divine Angubua, Arts and Culture Editor"
  }
] as ArticleIndexEntry[];

export type { ArticleIndexEntry };
export { article_index };