import { useRef, useState } from 'react';
import { Search as SearchIcon } from 'lucide-react';
import { ArticleIndexEntry } from '../assets/articles/article_index';
import { article_index } from '../assets/articles/article_index';
import { Link } from 'react-router-dom';
import { useMenuCloseEvent } from '../util/Hooks';
import { CustomCSS } from '../types/CustomCSS';
import { useTheme } from './ThemeContext';
import Fuse from 'fuse.js';

export interface SearchProps {
    className?: string;
}

export default function Search({ className }: SearchProps) {
    const [searchFocused, setSearchFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const searchButtonRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useMenuCloseEvent([searchButtonRef], () => {
        setSearchFocused(false);
    });

    const fuse = new Fuse(article_index, {
        keys: ['hed', 'byline'],
        includeScore: true
    });


    // Compute search results
    const maxSearchResults = 5;
    const computeSearchResults = (query: string): ArticleIndexEntry[] => {
        if (query === '') {
            return article_index.slice(0, maxSearchResults);
        }
        return fuse.search(query, {
            limit: maxSearchResults
        }).map((result) => result.item);
    };

    const t = useTheme();

    return (
        <div className={'w-fit h-fit hover-animation flex flex-col items-center rounded-none border-b-0 overflow-visible relative ' 
                        + (searchFocused ? 'selected' : '')}
             ref={searchButtonRef}>
            <div className={'flex flex-row items-center px-2 py-2'}
                 onClick={(e) => {
                     setSearchFocused(true);
                     if (inputRef.current) {
                         inputRef.current.focus();
                     }
                 }}
                 
                onFocus={() => {
                    setSearchFocused(true);
                }}>
                    <SearchIcon className="md:mr-2 size-8 md:size-4 shrink-0 opacity-100" strokeWidth={2} />
                    <input className={'h-min bg-transparent hover-animation transition-[width] duration-300 ' +
                                'outline-none text-ellipsis md:flex ' + (searchFocused ? 'selected w-20 md:w-[20vw] flex' : 'w-0 md:w-20')} 
                    ref={inputRef} placeholder="SEARCH" onChange={(e) => setSearchQuery(e.target.value)} />
            </div>
            {
                searchFocused && (
                    <ul className={`absolute -right-4 top-[3.5rem] md:top-8 md:left-0 bg-[var(--current-hover-text)] p-4 w-[100vw] md:w-full`}
                        style={{'--current-text': t.themeState.navPrimaryColor,
                                '--current-hover-background': t.themeState.navPrimaryColor,
                                '--current-hover-text': t.themeState.navTextColor
                    } as CustomCSS}>
                        {
                            computeSearchResults(searchQuery).slice(0, maxSearchResults).map((result, index) => (
                                <Link to={result.article_path} onClick={() => {
                                    if (inputRef.current) {
                                        inputRef.current.value = '';
                                    }
                                    setSearchQuery('');
                                    setSearchFocused(false);
                                }}
                                onBlur={(e) => {
                                    if (!e.currentTarget.parentElement?.contains(e.relatedTarget)) {
                                        setSearchFocused(false);
                                    }
                                }}>
                                    <li className="hover-animation text-[var(--current-background)] my-1 px-2 py-1 line-clamp-2" key={result.article_path}>
                                        {result.hed}
                                    </li>
                                </Link>
                            ))
                        }
                    </ul>
                )
            }
        </div>
    );
}