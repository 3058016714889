import { AdviceCardType } from '../types/AdviceCardType';
import './AdviceSection/AdviceSection.css';
import React from 'react';

export default function AdviceCard({adviceCard}: {adviceCard: AdviceCardType}) {
  return (
    <div className="advice-card" style={{backgroundColor: adviceCard.bgColor, color: adviceCard.textColor}}>
        <div className="advice-card-content">
            <p>{adviceCard.content}</p>
        </div>
        <div className="advice-card-footer">
            <p className="advice-card-author">{adviceCard.author},</p>
            <p className="advice-card-author-position">{adviceCard.position}</p>
        </div>
    </div>
  );
}